<template>
<div>
     <Header role = "admin"/>
  <div class="d-flex" id="wrapper">
    <Sidebar role = "admin"/>
  <div id="page-content-wrapper" style="background-color: #f3f2ef;">
     <div class="row">
       <div class="col-lg-11 col-12">
         <div class="text w-100">
          <router-view/>
         </div>
       </div>
       <!-- <div class="col-lg-4 d-lg-block d-none">
           <MessageList/>
       </div> -->
     </div>


  </div>
  </div>
  <div class="d-block d-lg-none" v-if="!['Doctor-New-Message', 'Doctor-Message-Chat', 'Doctor-Comment'].includes($route.name)">
       <Footer role = "admin"></Footer>
  </div>
  </div>
</template>

<script>
import axios from 'axios'
import Sidebar from '../layouts/Sidebar.vue'
import Header from '../layouts/Header.vue'
import Footer from '../layouts/CommonFooter.vue'
// import MessageList from '../../views/common/messagelist.vue'
import '../../css/app.css'
export default {

  name: 'app',
  components: {
    Sidebar,
    Header,
    Footer,
    // MessageList,
  },
  data() {
    return {
    adminID:'',
    adminUniqueID:'',
    isVisible: true
    }},
    created: async function () {
      await this.getAdmin();
    },
    mounted() {
    this.getAdmin();
    document.addEventListener('visibilitychange', this.handleVisibilityChange);
    window.addEventListener('focus', this.handlePageFocus);
  },
  unmounted() {
    document.removeEventListener('visibilitychange', this.handleVisibilityChange);
    window.removeEventListener('focus', this.handlePageFocus);
  },
  watch: {
    adminID: {
    immediate: true,
    handler: function(newUserID, oldUserID) {
      if (oldUserID && newUserID !== oldUserID) {
       location.reload();
      }
    },
  },
},
    methods: {
      handleVisibilityChange() {
      this.isVisible = !document.hidden;
      if (this.isVisible) {
        this.getAdmin();
        this.adminID = localStorage.getItem('AdminsignIn')
        this.adminUniqueID = localStorage.getItem('admin-uniqueId')
      }
    },
    handlePageFocus() {
      this.getAdmin();
      this.adminID = localStorage.getItem('AdminsignIn')
      this.adminUniqueID = localStorage.getItem('admin-uniqueId')

    },
      async getAdmin() {
  this.adminID = localStorage.getItem('AdminsignIn')
  this.adminUniqueID = localStorage.getItem('admin-uniqueId')

  const BASE_API_URL = process.env.VUE_APP_BASE_APIURL;
if(this.adminID){
      axios
    .get(`${BASE_API_URL}/admin/${this.adminID}/one`)
    .then((response) => {
      const adminData = response.data;
      if( adminData.adminunique_id != this.adminUniqueID ){
        window.location.href = "/";
      }
    })
  }
  else{
    window.location.href = "/";
  }
}  
    } 

}
</script>
<style>
  .text.w-100{
    margin-bottom:60px;
  }
</style>
